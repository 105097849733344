<template>
  <footer v-if="show" class="workers-container">
    <b-row>
      <pre>{{ getFooterText() }}</pre>
    </b-row>
  </footer>
</template>

<script>
export default {
    name: 'PayDayFooter',
    data() {
        return {
            show: false
        };
    },
    mounted() {
        setTimeout(() => {
            this.show = true;
        }, 1500);
    },
    methods: {
        getVersion() {
            return this.$version.fetchVersion() || -1;
        },
        getFooterText() {
            return `Copyright © 2024 Payday - Version: ${this.getVersion()}`;
        }
    }
};
</script>
<style scoped>
footer {
  margin-top: 0;
}
</style>
