import CompanyInfo from "@/model/Company/CompanyInfo";
import {downloadFile, generateFileName} from "@/store/storeExporter";
import getApi from "@/services/ApiService";
import {emptyExportData} from "@/store/defaultStoreStatus";

const INVOICES = 'invoices';
const WORKERS = 'workers';
const WAL_TRANSACTIONS = 'walTransactions';
const TRANSACTIONS = 'transactions';
const PIS_TRANSACTIONS = 'pisTransactions';
const PAYROLLS = 'payrolls';
const DEPOSITS = 'deposits';

const mutations = {
    addCompanies(state, value) {
        state.companies = {
            value: value.map(company => new CompanyInfo(company)),
            lastUpdated: Date.now()
        };
    },
    exportFile(state, payload) {
        downloadFile(payload.file.value, payload.file.fileName);
    },
    async fetchDepositToExport(state, payload) {
        const {
            numberOfPages, perPage, sortBy, order,
            type, status, filter, companies, format, tableRef
        } = payload.options;
        this.commit('setFetchingStatusForExport', {
            numberOfPages: numberOfPages,
            format,
            tableRef,
            t: payload.t,
            type: DEPOSITS
        });
        await getApi()
            .exportDeposits(numberOfPages, perPage, sortBy, order, type, status, filter, companies, format)
            .then(response => this.commit('updateExportProgress', {
                value: response.data,
                type: DEPOSITS
            }))
            .catch(error => console.error('error export Invoices', error));
    },
    async fetchInvoicesToExport(state, payload) {
        const {
            numberOfPages, perPage, sortBy, order,
            fromDate, toDate, fromPayDate, toPayDate, fromPayoutDate, toPayoutDate,
            companies, status, filter, format, tableRef,
        } = payload.options;
        this.commit('setFetchingStatusForExport', {
            numberOfPages: numberOfPages,
            format,
            tableRef,
            t: payload.t,
            type: INVOICES
        });
        await getApi().exportInvoices(
            numberOfPages, perPage, sortBy, order,
            fromDate, toDate, fromPayDate, toPayDate, fromPayoutDate, toPayoutDate,
            status, companies, filter, format)
            .then(response => this.commit('updateExportProgress', {
                value: response.data,
                type: INVOICES
            }))
            .catch(error => console.error('error export Invoices', error));
    },
    async fetchPayrollsToExport(state, payload) {
        const {
            numberOfPages, perPage, sortBy, order,
            fromDate, toDate, filter, groupId, status,
            format, tableRef
        } = payload.options;
        this.commit('setFetchingStatusForExport', {
            numberOfPages: numberOfPages,
            format,
            tableRef,
            t: payload.t,
            type: PAYROLLS
        });
        await getApi().exportPayrolls(
            numberOfPages, perPage, sortBy, order, fromDate,
            toDate, groupId, status, filter, format)
            .then(response => this.commit('updateExportProgress', {
                value: response.data,
                type: PAYROLLS
            }))
            .catch(error => console.error('error export Payrolls', error));

    },
    async fetchTransactionsToExport(state, payload) {
        const {
            numberOfPages, perPage, sortBy, order, isWal, companies, transactionTypes, transactionStatus, filter,
            fromDateFormatted, toDateFormatted, fromExecDateFormatted, toExecDateFormatted,
            format, tableRef
        } = payload.options;
        this.commit('setFetchingStatusForExport', {
            numberOfPages: numberOfPages,
            format,
            tableRef,
            t: payload.t,
            type: isWal ? WAL_TRANSACTIONS : TRANSACTIONS
        });
        await getApi().exportTransaction(
            isWal, numberOfPages, perPage, sortBy, order, companies,
            transactionTypes, transactionStatus, filter, fromDateFormatted,
            toDateFormatted, fromExecDateFormatted, toExecDateFormatted, format)
            .then(response => this.commit('updateExportProgress', {
                value: response.data,
                type: isWal ? WAL_TRANSACTIONS : TRANSACTIONS
            })).catch(error => console.error('error export Transaction', error));
    },
    async fetchPisTransactionsToExport(state, payload) {
        const {
            numberOfPages, perPage, sortBy, order, companies, transactionTypes, transactionStatus, filter,
            fromDateFormatted, toDateFormatted, fromExecDateFormatted, toExecDateFormatted,
            format, tableRef
        } = payload.options;
        this.commit('setFetchingStatusForExport', {
            numberOfPages: numberOfPages,
            format,
            tableRef,
            t: payload.t,
            type: PIS_TRANSACTIONS
        });
        await getApi().exportPisTransaction(
            numberOfPages, perPage, sortBy, order, companies,
            transactionTypes, transactionStatus, filter, fromDateFormatted,
            toDateFormatted, fromExecDateFormatted, toExecDateFormatted, format)
            .then(response => this.commit('updateExportProgress', {
                value: response.data,
                type: PIS_TRANSACTIONS
            })).catch(error => console.error('error export Transaction', error));
    },
    async fetchWorkerToExport(state, payload) {
        const {
            numberOfPages, perPage,
            sortBy, order, companies,
            userTypes, status, userInAppStatus, filter, format, tableRef
        } = payload.options;
        this.commit('setFetchingStatusForExport', {
            numberOfPages: payload.numberOfPages,
            format,
            tableRef,
            t: payload.t,
            type: WORKERS
        });
        await getApi().exportWorkers(
            numberOfPages, perPage, sortBy, order,
            companies, userTypes,
            status, userInAppStatus, format, filter)
            .then(response => this.commit('updateExportProgress', {
                value: response.data,
                type: WORKERS
            }))
            .catch(error => console.error('error exportWorkers', error));

    },
    async fetchWorkerForPartialExport(state, payload) {
        const {numberOfPages, t} = payload;
        const {workersIds, companyIds, format, tableRef} = payload.options;
        this.commit('setFetchingStatusForExport', {numberOfPages, format, tableRef, t, type: WORKERS});
        await getApi().exportPartialWorkers(workersIds, companyIds, format)
            .then(response => this.commit('updateExportProgress', {
                value: response.data,
                type: WORKERS
            }))
            .catch(error => console.error('error exportWorkers', error));
    },
    async fetchTransactionsForPartialExport(state, payload) {
        const {numberOfPages, t} = payload;
        const {workersIds, companyIds, format, tableRef} = payload.options;
        this.commit('setFetchingStatusForExport', {numberOfPages, format, tableRef, t, type: TRANSACTIONS});
        await getApi().exportPartialTransactions(workersIds, companyIds, format)
            .then(response => this.commit('updateExportProgress', {
                value: response.data,
                type: TRANSACTIONS
            }))
            .catch(error => console.error('error exportTransactionsPartial', error));
    },
    async fetchPisTransactionsForPartialExport(state, payload) {
        const {numberOfPages, t} = payload;
        const {workersIds, companyIds, format, tableRef} = payload.options;
        this.commit('setFetchingStatusForExport', {numberOfPages, format, tableRef, t, type: PIS_TRANSACTIONS});
        await getApi().exportPartialPisTransactions(workersIds, companyIds, format)
            .then(response => this.commit('updateExportProgress', {
                value: response.data,
                type: PIS_TRANSACTIONS
            }))
            .catch(error => console.error('error exportPisTransactionsPartial', error));
    },
    async fetchWalTransactionsForPartialExport(state, payload) {
        const {numberOfPages, t} = payload;
        const {workersIds, companyIds, format, tableRef} = payload.options;
        this.commit('setFetchingStatusForExport', {numberOfPages, format, tableRef, t, type: WAL_TRANSACTIONS});
        await getApi().exportPartialWalTransactions(workersIds, companyIds, format)
            .then(response => this.commit('updateExportProgress', {
                value: response.data,
                type: WAL_TRANSACTIONS
            }))
            .catch(error => console.error('error exportWalTransactionsPartial', error));
    },
    setFetchingStatusForExport(state, value) {
        const {numberOfPages, tableRef, format, t, type} = value;
        switch (type) {
        case WORKERS:
            state.exporters.workers.totalPages = numberOfPages;
            state.exporters.workers.isFetching = true;
            state.exporters.workers.tableRef = tableRef;
            state.exporters.workers.format = format;
            state.exporters.workers.t = t;
            state.exporters.workers.fileName = generateFileName(type, format);
            break;
        case INVOICES:
            state.exporters.invoices.totalPages = numberOfPages;
            state.exporters.invoices.isFetching = true;
            state.exporters.invoices.tableRef = tableRef;
            state.exporters.invoices.format = format;
            state.exporters.invoices.t = t;
            state.exporters.invoices.fileName = generateFileName(type, format);
            break;
        case PAYROLLS:
            state.exporters.payrolls.totalPages = numberOfPages;
            state.exporters.payrolls.isFetching = true;
            state.exporters.payrolls.tableRef = tableRef;
            state.exporters.payrolls.format = format;
            state.exporters.payrolls.t = t;
            state.exporters.payrolls.fileName = generateFileName(type, format);
            break;
        case TRANSACTIONS:
            state.exporters.transactions.totalPages = numberOfPages;
            state.exporters.transactions.isFetching = true;
            state.exporters.transactions.tableRef = tableRef;
            state.exporters.transactions.format = format;
            state.exporters.transactions.t = t;
            state.exporters.transactions.fileName = generateFileName(type, format);
            break;
        case PIS_TRANSACTIONS:
            state.exporters.pisTransactions.totalPages = numberOfPages;
            state.exporters.pisTransactions.isFetching = true;
            state.exporters.pisTransactions.tableRef = tableRef;
            state.exporters.pisTransactions.format = format;
            state.exporters.pisTransactions.t = t;
            state.exporters.pisTransactions.fileName = generateFileName(type, format);
            break;
        case WAL_TRANSACTIONS:
            state.exporters.walTransactions.totalPages = numberOfPages;
            state.exporters.walTransactions.isFetching = true;
            state.exporters.walTransactions.tableRef = tableRef;
            state.exporters.walTransactions.format = format;
            state.exporters.walTransactions.t = t;
            state.exporters.walTransactions.fileName = generateFileName(type, format);
            break;
        case DEPOSITS:
            state.exporters.deposits.totalPages = numberOfPages;
            state.exporters.deposits.isFetching = true;
            state.exporters.deposits.tableRef = tableRef;
            state.exporters.deposits.format = format;
            state.exporters.deposits.t = t;
            state.exporters.deposits.fileName = generateFileName(type, format);
            break;
        }
    },
    setLocale(state, value) {
        state.locale = value;
    },
    setPayroll(state, value) {
        state.payrollsToConfirm = value;
    },
    setTransaction(state, value) {
        state.transactionsToConfirm = value;
    },
    setWalTransaction(state, value) {
        state.walTransactionsToConfirm = value;
    },
    setPisTransaction(state, value) {
        state.pisTransactionsToConfirm = value;
    },
    setWorker(state, value) {
        state.workersToConfirm = value;
    },
    updateExportProgress(state, payload) {
        const {type, value} = payload;
        switch (type) {
        case WORKERS:
            state.exporters.workers.value = value;
            state.exporters.workers.lastUpdated = Date.now();
            state.exporters.workers.isFetching = false;
            state.exporters.workers.fetchEnded = true;
            break;
        case INVOICES:
            state.exporters.invoices.value = value;
            state.exporters.invoices.lastUpdated = Date.now();
            state.exporters.invoices.isFetching = false;
            state.exporters.invoices.fetchEnded = true;
            break;
        case PAYROLLS:
            state.exporters.payrolls.value = value;
            state.exporters.payrolls.lastUpdated = Date.now();
            state.exporters.payrolls.isFetching = false;
            state.exporters.payrolls.fetchEnded = true;
            break;
        case TRANSACTIONS:
            state.exporters.transactions.value = value;
            state.exporters.transactions.lastUpdated = Date.now();
            state.exporters.transactions.isFetching = false;
            state.exporters.transactions.fetchEnded = true;
            break;
        case WAL_TRANSACTIONS:
            state.exporters.walTransactions.value = value;
            state.exporters.walTransactions.lastUpdated = Date.now();
            state.exporters.walTransactions.isFetching = false;
            state.exporters.walTransactions.fetchEnded = true;
            break;
        case PIS_TRANSACTIONS:
            state.exporters.pisTransactions.value = value;
            state.exporters.pisTransactions.lastUpdated = Date.now();
            state.exporters.pisTransactions.isFetching = false;
            state.exporters.pisTransactions.fetchEnded = true;
                break;    
        case DEPOSITS:
            state.exporters.deposits.value = value;
            state.exporters.deposits.lastUpdated = Date.now();
            state.exporters.deposits.isFetching = false;
            state.exporters.deposits.fetchEnded = true;
            break;
        }
    },
    wipeProgressByType(state, type) {
        switch (type) {
        case WORKERS:
            state.exporters.workers = Object.assign({}, emptyExportData);
            break;
        case INVOICES:
            state.exporters.invoices = Object.assign({}, emptyExportData);
            break;
        case PAYROLLS:
            state.exporters.payrolls = Object.assign({}, emptyExportData);
            break;
        case TRANSACTIONS:
            state.exporters.transactions = Object.assign({}, emptyExportData);
            break;
        case WAL_TRANSACTIONS:
            state.exporters.walTransactions = Object.assign({}, emptyExportData);
            break;
        case PIS_TRANSACTIONS:
            state.exporters.pisTransactions = Object.assign({}, emptyExportData);
            break;    
        case DEPOSITS:
            state.exporters.deposits = Object.assign({}, emptyExportData);
            break;

        }
    },
    wipeAllProgress(state) {
        state.exporters.workers = Object.assign({}, emptyExportData);
        state.exporters.transactions = Object.assign({}, emptyExportData);
        state.exporters.walTransactions = Object.assign({}, emptyExportData);
        state.exporters.pisTransactions = Object.assign({}, emptyExportData);
        state.exporters.invoices = Object.assign({}, emptyExportData);
        state.exporters.payrolls = Object.assign({}, emptyExportData);
        state.exporters.deposits = Object.assign({}, emptyExportData);
    },
    wipeCompanies(state) {
        state.companies = {value: [], lastUpdated: null};
    },
    wipePayroll(state) {
        state.payrollsToConfirm = {value: 0, lastUpdated: null};
    },
    wipeTransaction(state) {
        state.transactionsToConfirm = {value: 0, lastUpdated: null};
    },
    wipePisTransaction(state) {
        state.pisTransactionsToConfirm = {value: 0, lastUpdated: null};
    },
    wipeWalTransaction(state) {
        state.walTransactionsToConfirm = {value: 0, lastUpdated: null};
    },
    wipeWorker(state) {
        state.workersToConfirm = {value: 0, lastUpdated: null};
    },
};

export default mutations;
